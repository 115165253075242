//const dateFormat = require("dateformat");
import inst_axios from './instansApi'
import Error_text from "../middleware/error_text";
import CryptoJS from "crypto-js";
////////////////////////////////////////////
const cryptData = (text) => CryptoJS.AES.encrypt(text, process.env.REACT_APP_PAYLOAD_KEY).toString();
//******************************************************************************************
export const userApi = {

    getUser(_, thunkAPI) {
        return inst_axios.get(`user`,)
            .then(result => result.data)
            .catch(function (error) {
                Error_text(error, thunkAPI.dispatch);
                return thunkAPI.rejectWithValue(error.message)
            });
    },

    addUser(data, rejectWithValue) {
        const payload = JSON.stringify({
            name: data.name,
            group_us: data.group_us,
            email: cryptData(data.email),
            password: cryptData(data.password)
        });
        return inst_axios.post(`user`, payload)
            .then(result => result.data)
            .catch(function (error) {
                console.log(error);
                return rejectWithValue(error.message)
            });
    },

    editUser(data, rejectWithValue) {
        const payload = JSON.stringify({
            name: data.name,
            group_us: data.group_us,
            email: cryptData(data.email),
            password: data.password ? cryptData(data.password) : null
        });
        return inst_axios.put(`user`, payload)
            .then(result => result.data)
            .catch(function (error) {
                console.log(error);
                return rejectWithValue(error.message)
            });
    },

    activeUser(data, rejectWithValue) {
        return inst_axios.patch(`user/${data.id}`, JSON.stringify({status: data.status}))
            .then(result => result.data)
            .catch(function (error) {
                console.log(error);
                return rejectWithValue(error.message)
            });
    },

    settingsUser(data, rejectWithValue) {
        console.log('settingsUser ', data)
        const payload = JSON.stringify({
            id: data.id,
            apikey: data.apikey,
            //apikey: cryptData(data.apikey),
            //privatekey: cryptData(data.privatekey)
        });
        return inst_axios.post(`user/settings/${data.id}`, payload)
            .then(result => result.data)
            .catch(function (error) {
                console.log(error);
                return rejectWithValue(error.message)
            });
    },

    deleteUser(id, rejectWithValue) {

        return inst_axios.delete(`user/${id}`)
            .then(result => result.data)
            .catch(function (error) {
                console.log(error);
                return rejectWithValue(error.message)
            });
    },

//////////////////////////////////////////
}
