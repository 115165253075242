import inst_axios from './instansApi'
////////////////////////////////////////////
//******************************************************************************************
export const webmasterApi = {

    getWebmasters(_, rejectWithValue) {
        //console.log('getWebmasters ');
        return inst_axios.get(`webmasters`)
            .then(result => result.data)
            .catch(function (error) {
                //console.log('getRefresh', error);
                return rejectWithValue(error.message)
            });
    },

    getHistory(data, rejectWithValue) {

        //console.log('data-getHistory ', data);
        return inst_axios.post(`webmasters/history`, data)
            .then(result => result.data)
            .catch(function (error) {
                //console.log('getRefresh', error);
                return rejectWithValue(error.message)
            });
    },

    getSaveHistory(data, rejectWithValue) {
        //console.log('data ', data);
        return inst_axios.post(`webmasters/history/save`, data)
            .then(result => result.data)
            .catch(function (error) {
                //console.log('getRefresh', error);
                return rejectWithValue(error.message)
            });
    },

//////////////////////////////////////////
}
