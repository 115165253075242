import axios from "axios";
import {loginApi} from './loginService';
//-------------------------------------------


const inst_axios = axios.create({
    mode: "cors",
    withCredentials: false, //забороняємо куки ! працюємо з JWT
    crossorigin: true,
    baseURL: 'https://gps.ecodom.vn.ua/solar/api/',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
    }
});
//-------------------------------------------------------
inst_axios.interceptors.request.use(config => {

    const accessToken = sessionStorage.getItem('accessToken');
    const refreshTokenSession = sessionStorage.getItem('refreshToken');
    const accessId = sessionStorage.getItem('accessId');

    let refreshToken = '';

    if (refreshTokenSession) {
        refreshToken = refreshTokenSession;
    } else {
        refreshToken = localStorage.getItem('refreshToken');
    }
    config.headers.Authorization = `Bearer ${accessToken ? accessToken : refreshToken}`;
    config.headers.accessid = accessId;

    if (sessionStorage.getItem('refreshToken')) {

    }


    return config;
});
//-------------------------------------------------------
inst_axios.interceptors.response.use(config => {
    return config;
}, async error => {
    //-----------------------------------
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetru) {
        originalRequest._isRetru = true;
        try {
            const refreshToken = sessionStorage.getItem('refreshToken');
            sessionStorage.removeItem('accessToken'); // Очищаємо стор
            // Запрос на оновлення токенів
            const reqRefreshUser = await loginApi.getRefresh();
            if (reqRefreshUser.tokens) {
                sessionStorage.setItem('accessToken', reqRefreshUser.tokens.accessToken)
                sessionStorage.setItem('accessId', String(reqRefreshUser.user.id));
                //------------------------------------------------------------------------------
                if (refreshToken) {
                    sessionStorage.setItem('refreshToken', reqRefreshUser.tokens.refreshToken)
                } else {
                    localStorage.setItem('refreshToken', reqRefreshUser.tokens.refreshToken)
                }
            }
            return inst_axios.request(originalRequest)
        } catch (e) {
            //console.log('error ', e)
        }
    }
    sessionStorage.clear()
    localStorage.clear()
    console.log('error authorization!', error.request.status)
    throw error;
})
//**********************************************
export default inst_axios;