import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import History from "./history";
import {getHistory, webmasterMapStateToProps} from "../../store/webmasterSlice";
import {Button, Spinner} from "react-bootstrap";
import css from "./history.module.css";
import {DatePicker} from "antd";
import dateFormat, {i18n} from "dateformat";
//-----------------------------------------------------------------------------------------------------
i18n.monthNames = [
    "Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",

    "Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень",
    "Вересень", "Жовтень", "Листопад", "Грудень",
];
////////////////////////////////////////////////////////////////////////////////////////////////////////
const HistoryContainer = (props) => {
    const [isValue, setIsValue] = useState('');
    const [isDate, setIsDate] = useState('');
    const dispatch = useDispatch();
    //--------------------------------------

    const handleDatePickerChange = (dateString) => {
        setIsDate(dateString);
        setIsValue('')

    }
    const handleButton = (dateString) => {
        setIsValue('За ' + dateFormat(isDate, "mmmm - yyyy"))
        const date = new Date(dateFormat(isDate, "yyyy-mm-dd"));

        const currentDateStart = new Date(date.getFullYear(), date.getMonth(), 1);
        const currentDateClear = currentDateStart.toISOString().split('T')[0]
        const dateStart = currentDateClear + ' 23:59:59'
        //--------------------------------------
        let lastDayDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());

        lastDayDate.setDate(lastDayDate.getDate() + 1);

        const currentDateClearEnd = lastDayDate.toISOString().split('T')[0]
        const dateEnd = currentDateClearEnd + ' 23:59:59'

        dispatch(
            getHistory(
                {
                    dateStart,
                    dateEnd,
                }
            )
        )
    }

    return (<>
        <span className={css.titleMount}> {isValue} </span>
        <DatePicker
            disabled={props.loading_webmaster}
            onChange={handleDatePickerChange}
            style={{
                height: "35px",
                width: "200px",
                border: "2px solid #fce48c",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "17px",
                margin: "5px",
                padding: "10px",

            }}
            size={"small"} picker="month"/>
        <Button className={css.formButtonH}
                onClick={handleButton}
                variant="outline-secondary"
                disabled={props.loading_webmaster}>
            Сформувати
        </Button>
        <hr/>
        <div className={css.serversBlock}>
            {props.loading_webmaster ? (<>
                <Spinner style={{marginTop: 50, marginBottom: 50}} animation="border" variant="secondary"/>
            </>) : (<>
                <div><History {...props} month={dateFormat(isDate, "mmmm")}/></div>
            </>)}
        </div>

    </>)
};

//----------------------------------------------------
export default connect(webmasterMapStateToProps, {
    getHistory,
})(HistoryContainer)

