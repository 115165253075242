import React from "react";
import {connect} from "react-redux";
import {headerMapStateToProps} from "../../store/loginSlice";
import ProfileUserForm from "./profile";
import {editUserAPI} from "../../store/usersSlice";
import {message} from "antd";
////////////////////////////////////////////////////////////////////////////////////////////////////////
const ProfileContainer = (props) => {
    //console.log('ProfileContainer ', props)
    const message_success = (text) => message.success(text);
    //--------------------------------------
    const onSubmitEdit = async (values) => {
        const result = await props.editUserAPI(values);
        if (!result.payload.error) {
            message_success(`Користувача ${values.name} змінено!`);
        }
    };

    return (<> <ProfileUserForm {...props} onSubmit={onSubmitEdit}/> </>)
};

//----------------------------------------------------
export default connect(headerMapStateToProps, {
    editUserAPI,
})(ProfileContainer)
