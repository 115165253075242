import React, {useState} from "react";
import css from './users.module.css'
import 'moment/locale/ru';
import {Button, Spinner, Table} from "react-bootstrap";
import enable from "./enable.png";
import disable from "./disable.png";
import {UserAddOutlined, EditOutlined, CloseOutlined, CloseCircleOutlined} from "@ant-design/icons";
import dateFormat from 'dateformat';
import {Popconfirm, message, Modal} from 'antd';
import AddUserForm from "./add_user";
import EditUserForm from "./edit_user";
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


/////////////////////////////////////////////////////////
const message_success = (text) => message.success(text);
const message_warning = (text) => message.warning(text);
//const message_error = (text) => message.error(text);
/////////////////////////////////////////////////////////
const UsersListAndGroups = (props) => {
    const [select_user, set_select_user] = useState({});
    //-------------------------------------
    const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
    //-------------------------------------
    const AddUserModalCancel = () => setIsAddUserModalVisible(false);
    const AddShowUserModal = () => setIsAddUserModalVisible(true);
    const EditUserModalCancel = () => setIsEditUserModalVisible(false);
    const EditShowUserModal = async (user) => {
        await set_select_user(user);
        setIsEditUserModalVisible(true);
    }
    //--------------------------------------

    //--------------------------------------
    const onSubmit = async (values) => {
        const result = await props.addUserAPI(values);
        if (!result.payload.error) {
            message_success(`Користувача ${result.payload.user.name} добавлено!`);
            AddUserModalCancel();
        }
    };
    //--------------------------------------
    const onSubmitEdit = async (values) => {
        const result = await props.editUserAPI(values);
        if (!result.payload.error) {
            message_success(`Користувача ${values.name} змінено!`);
            EditUserModalCancel();
        }
    };
    //--------------------------------------

    return (
        <>
            <Modal
                className={css.formAddUser}
                destroyOnClose={true}
                style={{backgroundColor: "#303032",}}
                bodyStyle={{backgroundColor: "#303032",}}
                open={isAddUserModalVisible}
                onCancel={AddUserModalCancel}
                footer={null}
                width={500}
                closeIcon={<CloseCircleOutlined className={css.Modal_close_icon}/>}
            >
                <span className={css.Modal_title}>Добавити користувача</span>

                <AddUserForm  {...props} onSubmit={onSubmit}/>
            </Modal>

            <Modal
                className={css.formAddUser}
                destroyOnClose={true}
                style={{backgroundColor: "#303032",}}
                bodyStyle={{backgroundColor: "#303032",}}
                open={isEditUserModalVisible}
                onCancel={EditUserModalCancel}
                footer={null}
                width={500}
                closeIcon={<CloseCircleOutlined className={css.Modal_close_icon}/>}
            >
                <span className={css.Modal_title}>Редагувати користувача id: {select_user.id}</span>
                <EditUserForm  {...props} onSubmit={onSubmitEdit} select_user={select_user}/>
            </Modal>

            <div className={css.divUsersBlock}>

                <div>
                    <div className={css.TitleTable}>
                        <b>
                            - Користувачі порталу
                        </b>
                    </div>
                    <div className={css.divTitleTable}>
                        <div className={css.divButtonOK}>
                            {' '} <Button variant="outline-info"
                                          onClick={AddShowUserModal}
                                          disabled={props.loading}

                        > <UserAddOutlined className={css.iconMenuButton}/> Добавити користувача </Button> {' '}

                        </div>
                    </div>
                </div>
                {props.loading ?
                    <div className={css.divSpiner}>
                        <Spinner animation="border" variant="secondary"/>
                    </div>

                    :

                    <div className={css.tableUsers}>
                        <Table striped bordered hover size="sm" variant="dark">
                            <thead>
                            <tr>
                                <th> ID</th>
                                <th> Дата</th>
                                <th> Им'я</th>
                                <th> Роль</th>
                                <th> Email</th>
                                <th> Статус</th>
                                <th> Змінити</th>
                                <th> Видалити</th>
                            </tr>
                            </thead>

                            <tbody>

                            {
                                props.users.map((user) =>
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{dateFormat(new Date(user.date), 'dd-mm-yyyy')}</td>
                                        <td className={css.tableName}>{user.name}</td>
                                        <td className={css.tableCode}>{user.group_us}</td>
                                        <td>{user.email}</td>

                                        <td>
                                            <div className={css.tableStatus}>

                                                <div className={css.divButtonStatus}>

                                                    {' '} <Button variant="outline-info" size="sm"
                                                                  style={{
                                                                      width: 150,
                                                                  }}
                                                                  onClick={() => {
                                                                      /*props.getDevice('1c-load')*/
                                                                      user.status === 1 ?
                                                                          props.activeUserAPI({
                                                                              id: user.id,
                                                                              status: 0
                                                                          }) :
                                                                          props.activeUserAPI({
                                                                              id: user.id,
                                                                              status: 1
                                                                          })
                                                                  }}
                                                >{user.status === 1 ?
                                                    <div>
                                                        <img src={enable} alt="status"
                                                             style={{marginRight: 10}}
                                                             className={css.imgStatus}/>
                                                        {' '} Деактивувати
                                                    </div> :
                                                    <div>
                                                        <img src={disable} alt="status"
                                                             style={{marginRight: 10}}
                                                             className={css.imgStatus}/>
                                                        {' '} Активувати
                                                    </div>
                                                }
                                                </Button> {' '}

                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div className={css.tableStatus}>

                                                <div className={css.divButtonStatus}>

                                                    {' '} <Button variant="outline-warning" size="sm"
                                                                  style={{
                                                                      width: 115,
                                                                  }}
                                                                  onClick={async () => {
                                                                      await EditShowUserModal(user);
                                                                  }}
                                                >
                                                    <div>
                                                        <EditOutlined
                                                            style={{
                                                                marginRight: 3,
                                                                fontSize: 22,
                                                                color: '#fce48c'
                                                            }}/>
                                                        {' '} Змінити
                                                    </div>
                                                </Button> {' '}

                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            {(user.email !== 'wizimob@gmail.com') &&
                                                <div className={css.tableStatus}>

                                                    <div className={css.divButtonStatus}>
                                                        {' '} <Popconfirm

                                                        title={
                                                            <>
                                                            <span>
                                                                Ви впевнені, що хочете видалити користувача
                                                            </span>
                                                                <span className={css.tableConfirm}><b>{
                                                                    ' ' + user.name + '?'}</b>
                                                            </span>
                                                            </>}
                                                        onConfirm={async () => {
                                                            const deleteUser = await props.deleteUserAPI(user.id);
                                                            deleteUser.hasOwnProperty('payload') &&
                                                            message_warning('Користувача ' +
                                                                user.id + ' ' + user.name + ' видалено!');
                                                        }}
                                                        //onCancel={cancel}
                                                        style={{backgroundColor: '#3f3f42', color: '#ffffcc'}}
                                                        okText="Так"
                                                        cancelText="Ні"
                                                    >
                                                        <Button variant="outline-danger" size="sm"
                                                                style={{
                                                                    width: 115,
                                                                }}

                                                        >
                                                            <div>
                                                                <CloseOutlined
                                                                    style={{
                                                                        marginRight: 3,
                                                                        fontSize: 22,
                                                                        color: '#8B0000'
                                                                    }}/>
                                                                {' '} Видалити
                                                            </div>
                                                        </Button>
                                                    </Popconfirm>{' '}

                                                    </div>
                                                </div>}
                                        </td>

                                    </tr>
                                )
                            }
                            </tbody>

                        </Table>
                    </div>}

                <hr/>

            </div>
        </>
    )

};

export default UsersListAndGroups
