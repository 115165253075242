import React from "react";
import {Button, Spinner} from "react-bootstrap";
import {Form, Field} from 'react-final-form'
import Alert from "react-bootstrap/Alert";
import css from "./settings.module.css";

////////////////////////////////////////////////////////////////////////////////////////
const SettingsUserForm = (props) => {

    return (<>
        <div className={css.TitleUser}>- Налаштування користувача</div>


        <div className={css.formProfile}>

            <Form
                onSubmit={props.onSubmit}
                initialValues={{
                    id: props.user.id,
                    //apikey: '',
                    //privatekey: '',
                }}
                render={({handleSubmit, form , submitting, pristine, values}) => (
                    <form
                        onSubmit={event => {
                            handleSubmit(event).then(()=>{
                                form.reset();
                            });
                        }}
                    >
                        {props.error && (
                            <Alert className={css.formModalAlert} variant='danger'>
                                <b>{props.error} </b>
                            </Alert>
                        )}
                        <div className="mb-3">
                            <label className={css.formModalForm}>Тариф:</label>
                            <div>
                                <Field
                                    className={css.formInput}
                                    name="apikey"
                                    component="input"
                                    type="text"
                                    placeholder="Введіть Тариф"
                                    autoFocus
                                /><span className={css.formModalFormText}> грн/kW </span>

                            </div>

                        </div>

                        {/*<div className="mb-3">
                            <label className={css.formModalForm}>Нотатки:</label>
                            <Field
                                className={css.formInput}
                                name="privatekey"
                                component="input"
                                type="text"
                                placeholder="Введіть приватні нотатки"
                                autoFocus
                            />
                        </div>*/}


                        <Button className={css.formUserButton}
                                variant="outline-secondary" type="submit"
                                /*onClick={reset}*/
                                disabled={
                                    !(values.apikey && !(Number.isNaN(Number(values.apikey))))
                                    || values.apikey.length < 1
                                    || props.loading || submitting || pristine
                                }>
                            {props.loading ? (
                                <>
                                    <Spinner
                                        className={css.formButtonSpinner}
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> <span>Завантаження...</span>
                                </>
                            ) : (<> Зберегти</>)}

                        </Button>

                    </form>
                )}
            />
        </div>
    </>)
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default SettingsUserForm
