import React from 'react';
import {ConfigProvider} from "antd";
import {Route, Routes} from 'react-router-dom';
import locale from 'antd/lib/locale/uk_UA';
import css from './home.module.css'
import Webmasters from "../webmasters/webmasters-container";
import UsersContainer from "../users/users_container";
import ProfileContainer from '../profile/profile-container';
import SettingsContainer from "../settings/settings-container";
import HistoryContainer from "../history/history-container";
//-----------------------------------------------------
const HomeRoute = (props) => {
    return (<div className={css.Global}>
        <div className={css.widgetTicker}>
            <Webmasters />
        </div>
        <div className={css.HomeRow}>
            <div className={css.widgetBlock}>

                {/*<Widget {...props}/>*/}
            </div>

            <div className={css.OrderBlock}>
                {/*<OrdersContainer {...props} />*/}
            </div>
        </div>

    </div>)
};
//*********************************************************************

///////////////////////////////////////////////////////////////////////
const Home = (props) => {

    return (<>
        <ConfigProvider locale={locale}>
            <div>
                <Routes>
                    <Route path="/" element={<HomeRoute {...props}/>}/>
                    <Route path="/history" element={<HistoryContainer {...props}/>}/>
                    <Route path="/profile" element={<ProfileContainer {...props}/>}/>
                    <Route path="/settings" element={<SettingsContainer {...props}/>}/>
                    {props.user.group === 'admin' && <Route path="/users" element={<UsersContainer/>}/>}
                </Routes>
            </div>
        </ConfigProvider>


    </>)
};

//*******************************
export default Home
