import React from 'react';
import css from './loader.module.css'
import {Spinner} from "react-bootstrap";
///////////////////////////////////////////////////////////////////////
const Loader = (props) => {

    return (<>
        <img className={css.formLogImg} src="/logo-PIWI.png" alt="logo-piwi"/>
        <Spinner animation="grow" variant="light" />
    </>)

};

//*******************************
export default Loader
