import React, {useState} from 'react';
import css from "./webmasters.module.css";
import {Button} from "react-bootstrap";

import dateFormat from 'dateformat';
import {Modal, message, Input} from "antd";
import {useDispatch} from "react-redux";
import {loadingWebmasterAPI} from "../../store/webmasterSlice";
import moment from "moment";

///////////////////////////////////////////////////////////////////////

const Webmaster = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isID, setIsID] = useState('');
    const [isName, setIsName] = useState('');
    const [isDate, setIsDate] = useState('');
    const [isValue, setIsValue] = useState('');
    const [isType, setIsType] = useState('cost');

    const dispatch = useDispatch();


    const showModal = (id, name, type) => {
        setIsModalVisible(true);
        setIsID(id);
        setIsName(name);
        setIsType(type);
        //------------------------------------
        const dayMilliseconds = 24 * 60 * 60 * 1000;

        let currentDate = new Date();
        currentDate.setTime(currentDate.getTime() - dayMilliseconds);
        //console.log('currentDate ', dateFormat(currentDate, "dd-mm-yyyy"));
        setIsDate(dateFormat(currentDate, "dd-mm-yyyy"));

    };
    const handleCancel = () => {
        setIsModalVisible(false);
        setIsValue('');
        setIsName('');
    };
    const SaveVersionApp = async () => {
        setIsModalVisible(false);
        const resultSave = await props.getSaveHistory({id: isID, type: isType, value: isValue});

        setIsValue('');
        setIsName('');
        //console.log('resultSave ', resultSave);
        if (resultSave.payload.error) {
            message.error(resultSave);
        } else {
            message.success('ok');
            dispatch(loadingWebmasterAPI())
        }
        setIsModalVisible(false);
    };
    ////////////////////////////////////////////////
    let total_current = 0
    let total_power_day = 0
    let total_power_yesterday = 0
    let total_power_consumed = 0
    props.webmasters.forEach(function (item) {
        total_current += Number(item.power_current)
        total_power_day += Number(item.power_day)
        total_power_yesterday += Number(item.power_yesterday)
        total_power_consumed += Number(item.consumed_yesterday)
    })
    const taruf = total_power_yesterday * props.taruf;
    //console.log('total_current ', total_current);

    return (<div className={css.GlobalTable}>
        <Modal

            title={<span className={css.Modal_title}>Введіть дані {isName}</span>}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={300}
        >
            <b>на {isDate}</b>
            <div className={css.Modal_Button}>
                <Input
                    focus="true"
                    placeholder="kw"
                    variant="outline-secondary"
                    value={isValue}
                    onChange={(e) => {
                        setIsValue(e.target.value.replace(/[^\d.]/ig, ''))
                    }}
                />
                <div className={css.Save_Button}>
                    <Button variant="outline-secondary" onClick={SaveVersionApp}>Зберегти</Button>
                </div>
            </div>
        </Modal>

        {props.webmasters.map((master) => <div key={master.id}>
            <div className={css.serverDateUpdate}>{
                /*await formatDate(master.date_update)*/
                dateFormat(master.date_update, "dd, mm, yyyy, HH:MM:ss")

            } </div>
            <div className={css.formServer}>

                <div className={css.serverButtonTitle}>
                    <Button href={master.url_webmaster}
                            target="_blank" className={master.status === 0 ? css.formButtonAlert : css.formButton}
                            variant="outline-secondary">
                        {master.name}
                    </Button>

                </div>

                <div className={css.serverColumC}>
                    <span className={master.status === 0 ? css.spanTextAlert : css.spanText}>
                        {master.power_current} kW</span>

                </div>
                <div className={css.serverColum}>
                    <span className={master.status === 0 ? css.spanTextAlert : css.spanText}>
                        {master.power_day} kW/h</span>

                </div>
                <div className={css.serverColumYesterdayBlockB} style={{}}>
                    <Button className={css.serverColumYesterdayButton}
                            variant="outline-secondary" onClick={() => {
                        showModal(master.id, master.name, 'cost')
                    }}><span
                        className={master.status === 0 ? css.spanTextAlert : css.spanText}>
                            {master.power_yesterday} kW</span></Button>

                </div>
                <div className={css.serverColumYesterdayBlockB} style={{}}>
                    <Button className={css.serverColumYesterdayButton}
                            variant="outline-secondary" onClick={() => {
                        showModal(master.id, master.name, 'consumption')
                    }}><span
                        className={master.status === 0 ? css.spanTextAlert : css.spanText}>
                            {master.consumed_yesterday} kW</span></Button>

                </div>

                <div className={css.serverColumC}>
                    {master.status === 0 ?
                        <span className={css.spanTextAlert}>немає зв'язку</span> :

                        <span className={master.offline > 0 ? css.spanTextWarning : css.spanTextOn}>
                        {master.offline}
                    </span>
                    }


                </div>


            </div>
        </div>)}
        <div className={css.serversBlock}>
            <span className={css.titleTable}>СУМАРНО:</span>
            <span className={css.titleTable}>{total_current.toFixed(2)} </span>
            <span className={css.titleTable}>{total_power_day.toFixed(2)} </span>
            <span className={css.titleTableTar}>{total_power_yesterday.toFixed(2)}</span>
            <span className={css.titleTable}>{total_power_consumed.toFixed(2)}</span>
            <span className={css.titleTableTar}>{Math.round(taruf.toFixed(2))} грн</span>

        </div>

    </div>)
};

//*******************************
export default Webmaster
