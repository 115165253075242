import {message} from 'antd';
import {isAuth, isError} from "../store/loginSlice";
//**************************************
//-------------------------------
const Error_text = (error, dispatch) => {
    if (error.response.status === 401) dispatch(isAuth(false));

    switch (error.message) {
        case 'Request failed with status code 401':
            dispatch(isError('Користувач не авторизований!'));
            break

        case 'Network Error':
            message.error('Помилка мережі спробуйте ще раз...');
            break

        default:
            return {code: 0, text: error.message};
    }
}
//------------------------------
export default Error_text;

