import './App.css';
import LoginContainer from "./components/login/login_container";

import { ConfigProvider } from 'antd';
import uk_UA from 'antd/es/locale/uk_UA';

function App() {

    return (
        <div className="App">
            <header className="App-header">
                <ConfigProvider locale={uk_UA}>
                    <LoginContainer/>
                </ConfigProvider>
            </header>
        </div>
    );
}

//*************************
export default App;
