import React from "react";
import {Button, Spinner} from "react-bootstrap";
import {Form, Field} from 'react-final-form'
import Alert from "react-bootstrap/Alert";
import css from "./profile.module.css";

////////////////////////////////////////////////////////////////////////////////////////
const ProfileUserForm = (props) => {

    return (<>
        <div className={css.TitleUser}>- Профіль користувача</div>


        <div className={css.formProfile}>

            <Form
                onSubmit={props.onSubmit}
                initialValues={{
                    id: props.user.id,
                    name: props.user.name,
                    group_us: props.user.group,
                    email: props.user.email
                }}
                render={({handleSubmit, values}) => (
                    <form onSubmit={handleSubmit}>
                        {props.error && (
                            <Alert className={css.formModalAlert} variant='danger'>
                                <b>{props.error} </b>
                            </Alert>
                        )}
                        <div className="mb-3">
                            <label className={css.formModalForm}>Ім'я:</label>
                            <Field
                                className={css.formInput}
                                name="name"
                                component="input"
                                type="text"
                                placeholder="Введіть ім'я"
                                autoFocus
                            />
                        </div>
                        <div className="mb-3">
                            <label className={css.formLogLabel}>Роль:</label>
                            <Field
                                className={css.formInputDisabled}
                                name="group_us"
                                component="select"
                                disabled={true}
                            >
                                <option className={css.formOptionsInput} value="admin">Адміністратор</option>
                                <option className={css.formOptionsInput} value="user">Користувач</option>
                                <option className={css.formOptionsInput} value="guest">Гість</option>
                            </Field>
                        </div>
                        <div className="mb-3">
                            <label className={css.formLogLabel}>Пошта:</label>
                            <Field
                                className={css.formInputDisabled}
                                name="email"
                                component="input"
                                type="email"
                                placeholder="Введіть email"
                                disabled={true}
                            />
                        </div>
                        <div className="mb-3">
                            <label className={css.formLogLabel}>Пароль:</label>
                            <Field
                                className={css.formInput}
                                name="password"
                                component="input"
                                type="password"
                                placeholder="Введіть пароль"
                            />
                        </div>

                        <Button className={css.formUserButton}
                                variant="outline-secondary" type="submit" disabled={
                            !(values.name && values.email && (values.name.length > 3)) || props.loading
                        }>
                            {props.loading ? (
                                <>
                                    <Spinner
                                        className={css.formButtonSpinner}
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> <span>Завантаження...</span>
                                </>
                            ) : (<> Зберегти</>)}

                        </Button>
                    </form>
                )}
            />
        </div>
    </>)
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default ProfileUserForm
