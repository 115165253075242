import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import Login from "./login";
import {userLoginMapStateToProps, userLoginAPI, refreshLoginAPI} from '../../store/loginSlice'
import Loader from '../loader/loader'
import HomeContainer from "../home/home_container";

/////////////////////////////////////////////////////////////////////
const LoginContainer = (props) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.login.loading)
    const logout = useSelector(state => state.login.logout)
    const isAuth = useSelector(state => state.login.isAuth)

    const onSubmit = async (values) => {
        dispatch(userLoginAPI(values));
    };


    useEffect(() => {
        sessionStorage.clear() // Очищаємо стор
        !logout && dispatch(refreshLoginAPI()) // Автологін
    }, [dispatch])


    return (
        <>
            {isAuth ? <HomeContainer/> :
                (!logout && loading) ? <Loader/>
                    : <Login {...props} onSubmit={onSubmit}/>
            }
        </>
    )
};

//let  URLprofileCOnteiner = withRouter(ProfileContainer); Узнать конечний путь
//*************************************************
//export default connect({user, error}, {dispatch, })(LoginContainer)
export default connect(userLoginMapStateToProps, {
    userLoginAPI,
})(LoginContainer);
