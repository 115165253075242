import React from 'react';
import css from "./history.module.css";
import Table from 'react-bootstrap/Table';
import dateFormat from 'dateformat';

///////////////////////////////////////////////////////////////////////


const History = (props) => {

//-----------------------------------------
    console.log('History props ', props)
    //props.webmasters.map((master) => <div key={master.id}>
    return (<div className={css.GlobalTable}>
        <Table className={css.Table} bordered>
            <thead>
            <tr>
                {/*<th>Дата</th>*/}
                {props.history.map((history) => <th key={history.id}>
                    {history.name}</th>)}
            </tr>
            </thead>
            <tbody>

            <tr>
                {/*<td></td>*/}
                {props.history.map((history) => <td key={history.id + 'g'}>
                    <table style={{width: '100%',}}>
                        <tbody>
                        <tr className={css.Tr}>
                            <td className={css.TdR}>генерація</td>
                            <td className={css.TdL}>спожито</td>
                        </tr>
                        </tbody>
                    </table>
                </td>)}
            </tr>

            <tr>
                {/*<td style={{fontSize: '0.9em', color: '#cacaca'}}>{props.month}</td>*/}
                {props.history.map((history) => <td key={history.id + 'kw'}>
                    <table style={{width: '100%',}}>
                        <tbody>
                        <tr className={css.Tr}>
                            <td className={css.TdkWR}>{history.total_power_production} kW</td>
                            <td className={css.TdkWL}>{history.total_power_consumed} kW</td>
                        </tr>
                        </tbody>
                    </table>
                </td>)}
            </tr>

            <tr>
                {/*<td>ІТОГО</td>*/}
                {props.history.map((history) => <td className={css.TdkItogo} key={history.id + 'kw'}>

                    {history.total_power_production - history.total_power_consumed} kW
                    <span className={css.TdkItogoSpan}> ~
                        {history.itogo}грн </span>
                </td>)}
            </tr>

            <tr>
                {/*<td style={{fontSize: '0.9em', color: '#cacaca'}}>дата</td>*/}
                {props.history.map((history) => <td key={history.id + 'kw'}>
                    <table style={{width: '100%',}}>
                        <tbody>
                        {history.days.map((day) => <tr key={day.id + 'his'}>
                            <td className={css.TdkWRDay}>
                                <span className={css.TdkWRDate}>
                                    {dateFormat(day.date_update, "dd")}
                                </span>
                                {day.value}
                            </td>
                            <td className={css.TdkWLDay}>{day.consumed}</td>
                        </tr>)}

                        </tbody>
                    </table>
                </td>)}
            </tr>


            </tbody>
        </Table>

    </div>)
};

//*******************************
export default History
