import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import {MdAccountCircle, MdExitToApp} from "react-icons/md";
import {IconContext} from "react-icons";
import {HomeOutlined, AimOutlined, SettingOutlined, InfoCircleOutlined, UsergroupAddOutlined} from '@ant-design/icons';
import css from "./header.module.css";
import {Link, useLocation} from 'react-router-dom';
import {notification} from 'antd';
import {CountdownCircleTimer} from 'react-countdown-circle-timer'
//////////////////////////////////////////////////////////////////////////////
const getGroup = (group) => {
    switch (group) {
        case 'admin':
            return css.icon_auth_admin;
        case 'user':
            return css.icon_auth_user;
        default:
            return css.icon_auth_client;
    }
};
//-----------------------------------------------------------
const openNotification = () => {
    notification.info({
        style: {
            backgroundColor: 'rgb(172,216,255)',
        },
        top: 100,
        message: 'Розробив програму:',
        description:
            'Луговський О.С. (wizimob@gmail.com)',
        onClick: () => {
            //console.log('Notification Clicked!');
        },
    });
};
//-----------------------------------------------------------
const Header = (props) => {

    //-------------------------------------------------------
    const {name, group} = props.user;
    //let navigate = useNavigate();
    let location = useLocation();


    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" className={css.headerB}>
                <Navbar.Brand href="#home">
                    <img className={css.headerImg} src="/logo-PIWI.png" alt="logo-piwi"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav ">
                    <Nav className="mr-auto">

                        <Nav.Link as={Link} to="/" active={location.pathname === '/'}>

                            <div className={css.NameMenu}><HomeOutlined className={css.iconMenu}/>
                                Головна
                            </div>
                        </Nav.Link>

                        {(group === 'admin' || group === 'user') ?
                            <Nav.Link as={Link} to="/history" active={location.pathname === '/device'}>
                                <div className={css.NameMenu}>
                                    <AimOutlined className={css.iconMenu}/>
                                    Історія
                                </div>
                            </Nav.Link> : <></>}

                        {group === 'admin' && <Nav.Link as={Link} to="/users" active={location.pathname === '/users'}>
                            <div className={css.NameMenu}>
                                <UsergroupAddOutlined className={css.iconMenu}/>
                                Користувачі
                            </div>
                        </Nav.Link>}

                        {group !== 'guest' &&
                            <Nav.Link as={Link} to="/settings" active={location.pathname === '/settings'}>
                                <div className={css.NameMenu}>
                                    <SettingOutlined className={css.iconMenu}/>
                                    Налаштування
                                </div>
                            </Nav.Link>}


                        <Nav.Link>
                            <div className={css.NameMenu}>

                                <InfoCircleOutlined onClick={() => openNotification('top')} className={css.iconMenu}/>

                            </div>
                        </Nav.Link>

                        <span className={css.Timer}><CountdownCircleTimer
                            isPlaying
                            size={50}
                            duration={180}
                            colors={['#004777', '#F7B801', '#ac0101', '#d70000']}
                            colorsTime={[180, 70, 30, 0]}
                            onComplete={() => ({shouldRepeat: true, delay: 1})}
                        >
                            {({remainingTime}) => remainingTime}
                        </CountdownCircleTimer></span>
                    </Nav>


                </Navbar.Collapse>

                <Nav.Link as={Link} to="/profile" active={location.pathname === '/profile'}>
                    <div className={'text-secondary ' + css.auth}>
                        <IconContext.Provider value={{size: '2.3em'}}>
                            <div>
                                <MdAccountCircle/><span
                                className={getGroup(group)}>{name}</span>
                            </div>
                        </IconContext.Provider>
                    </div>
                </Nav.Link>

                <Nav className={css.exit}>
                    <Nav.Link as={Link} to="/" onClick={() => props.logout()}>
                        <div className={css.NameMenu}>
                            <MdExitToApp className={css.iconMenu}/> Вихід
                        </div>
                    </Nav.Link>
                </Nav>
            </Navbar>
        </>
    )

};
//----------------------
export default Header;
