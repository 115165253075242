import React from 'react';
import Button from 'react-bootstrap/Button';
import css from './login.module.css'
import Alert from 'react-bootstrap/Alert';
import {Spinner} from "react-bootstrap";
import {Form, Field} from 'react-final-form'

///////////////////////////////////////////////////////////////////////

const Login = (props) => {


    return (<div className={css.formGlobal}>
        <img className={css.formLogImg} src="/logo-PIWI.png" alt="logo-piwi"/>
        <Form
            onSubmit={props.onSubmit}
            initialValues={{remember_me: false,}}
            render={({handleSubmit, form, submitting, pristine, values}) => (
                <form className={css.formLogin} onSubmit={handleSubmit}>
                    {props.error && (
                        <Alert variant='danger'>
                            <b>{props.error} </b>
                        </Alert>
                    )}
                    <div className="mb-3">
                        <label className={css.formLogLabel}>Пошта:</label>
                        <Field
                            className={css.formInput}
                            name="email"
                            component="input"
                            type="email"
                            placeholder="Введіть email"
                            autoFocus
                        />
                    </div>
                    <div className="mb-3">
                        <label className={css.formLogLabel}>Пароль:</label>
                        <Field
                            className={css.formInput}
                            name="password"
                            component="input"
                            type="password"
                            placeholder="Введіть пароль"
                        />
                    </div>
                    <div className={css.formDivCheckbox}>
                        <label className={css.formLogCheckboxLabel}>Запам'ятати</label>
                        <Field
                            className={css.chk}
                            name="remember_me"
                            component="input"
                            type="checkbox"/>
                    </div>

                    <Button className={css.formLogButton}
                            variant="outline-secondary" type="submit" disabled={
                        !(values.email && values.password) || props.loading
                    }>
                        {props.loading ? (
                            <>
                                <Spinner
                                    className={css.formButtonSpinner}
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> <span>Завантаження...</span>
                            </>
                        ) : (<> Вхід</>)}

                    </Button>
                    {/*<pre>{JSON.stringify(!values.email && !values.password, 0, 2)}</pre>*/}
                </form>
            )}
        />
    </div>)
    //return <div><ReduxFormLogin {...props} onSubmit={onSubmit}/></div>
};

//*******************************
export default Login
