import {configureStore} from '@reduxjs/toolkit'
import loginReducer from './loginSlice'
import usersReducer from './usersSlice'
import webmasterReducer from './webmasterSlice'
//------------------------------------------
export const store = configureStore({
    reducer: {
        login: loginReducer,
        users: usersReducer,
        webmaster: webmasterReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    /*middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            /!*thunk: {
                extraArgument: myCustomApiService,
            },*!/
            serializableCheck: {
                ignoredActions: ['form/initForm', 'form/changeValue', "form/setErrors", "form/validateForm"]
            }
        }),*/
})