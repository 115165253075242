import inst_axios from './instansApi'
////////////////////////////////////////////
//******************************************************************************************
export const loginApi = {

    getRefresh(_, rejectWithValue) {

        return inst_axios.get(`refresh`)
            .then(result => result.data)
            .catch(function (error) {
                //console.log('getRefresh', error);
                return rejectWithValue(error.message)
            });
    },


    posLogin(loginPass, rejectWithValue) {
        const data = JSON.stringify({
            email: loginPass.email,
            password: loginPass.password
        });
        return inst_axios.post(`login`, data)
            .then(result => result.data)
            .catch(function (error) {
                console.log(error);
                return rejectWithValue(error.message)
            });
    },

//////////////////////////////////////////
}
