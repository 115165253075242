import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import Webmaster from "./webmaster";
import {getSaveHistory, loadingWebmasterAPI, webmasterMapStateToProps} from "../../store/webmasterSlice";
import {Spinner} from "react-bootstrap";
import css from "./webmasters.module.css";

////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebmastersContainer = (props) => {
    const dispatch = useDispatch();
    //console.log('WebmastersContainer ', props)
    //const message_success = (text) => message.success(text);
    //--------------------------------------


    useEffect(() => {
        dispatch(loadingWebmasterAPI())
        setInterval(() => {

            dispatch(loadingWebmasterAPI())
        }, 180000);
    }, [dispatch])

    return (<>
        <div className={css.serversBlock}>
            <span className={css.titleTable}>СТАНЦІЯ</span>
            <span className={css.titleTable}>ЗАРАЗ</span>
            <span className={css.titleTable}>СЬОГОДНІ</span>
            <span className={css.titleTable}>ВЧОРА</span>
            <span className={css.titleTable}>СПОЖИТО</span>
            <span className={css.titleTable}>АВАРІЯ</span>
            {props.loading_webmaster ? (<>
                <Spinner style={{marginTop: 50, marginBottom: 50}} animation="border" variant="secondary"/>
            </>) : (<>
                <div><Webmaster {...props} /></div>
            </>)}
        </div>

    </>)
};

//----------------------------------------------------
export default connect(webmasterMapStateToProps, {
    //loadingPairsAPI,
    //get_pair1,
    //get_pair2,
    getSaveHistory,
})(WebmastersContainer)

