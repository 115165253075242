import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {webmasterApi} from "../services/webmasterService";
//************************************************************

//***************************| - Thunk - |***************************
//*******************************************************************
export const loadingWebmasterAPI = createAsyncThunk(
    'webmaster/loadingWebmasterAPI',
    async (_, {rejectWithValue, /*dispatch*/}) => {
        return await webmasterApi.getWebmasters(_, rejectWithValue);
    }
)
//-------------------------------------------------------------------------------------------------
export const getSaveHistory = createAsyncThunk(
    'webmaster/getSaveHistory',
    async (data, {rejectWithValue, /*dispatch*/}) => {
        return await webmasterApi.getSaveHistory(data, rejectWithValue);
    }
)
//-------------------------------------------------------------------------------------------------
export const getHistory = createAsyncThunk(
    'webmaster/getHistory',
    async (data, {rejectWithValue, /*dispatch*/}) => {
        return await webmasterApi.getHistory(data, rejectWithValue);
    }
)
//-------------------------------------------------------------------------------------------------
//#################################################################################################
const initialState = {
    webmasters: [],
    history: [],
    taruf: 0,
    loading: false,
    error: '',

}
//#################################################################################################
export const webmasterSlice = createSlice({
    name: 'webmaster',
    initialState,
    reducers: {

        isError: (state, action) => {
            state.error = action.payload;
        },

    },
    extraReducers: {
        //**************************************************************
        //loadingPairAPI
        [loadingWebmasterAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [loadingWebmasterAPI.fulfilled]: (state, action) => {
            if (!action.payload.error) {

                state.webmasters = action.payload.webmasters;
                state.taruf = action.payload.taruf;
            }
            state.loading = false;
        },
        [loadingWebmasterAPI.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        //**************************************************************
        //getHistory
        [getHistory.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [getHistory.fulfilled]: (state, action) => {
            if (!action.payload.error) {
                //console.log('action.history ', action.payload);
                state.history = action.payload.history;
                state.taruf = action.payload.taruf;
            }
            state.loading = false;
        },
        [getHistory.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },

        //**************************************************************

    }
})
// Selector ********************************************************
export const webmasterMapStateToProps = (state) => ({
    error_webmaster: state.webmaster.error,
    loading_webmaster: state.webmaster.loading,
    webmasters: state.webmaster.webmasters,
    history: state.webmaster.history,
    taruf: state.webmaster.taruf,

});

//******************************************************************
// Action creators are generated for each case reducer function
export const {
    isError,
} = webmasterSlice.actions
export default webmasterSlice.reducer
