import React from "react";
import Home from "./home";
import Header from "../header/header";
import css from './home.module.css'
import {connect} from "react-redux";
import {headerMapStateToProps, logout} from "../../store/loginSlice";
import {getUserAPI} from "../../store/usersSlice";
/////////////////////////////////////////////////////////////////////
const HomeContainer = (props) => {

// В запросе задано нет профайла, может бить нул или ундефайн
    return (
        <>
            <div className='container-xl'>
                <div className={css.globalBlock}>

                    <div className={css.HeaderBlock}>
                        <Header {...props}/>
                    </div>

                    <div className={css.HomeBlock}>
                        <Home {...props}/>
                    </div>
                </div>

            </div>

        </>
    )
};

//let  URLprofileCOnteiner = withRouter(ProfileContainer); Узнать конечний путь

//*************************************************
export default connect(headerMapStateToProps, {
    logout,
    getUserAPI
})(HomeContainer)
//export default HomeContainer