import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {loginApi} from '../services/loginService'
import CryptoJS from 'crypto-js';
import Error_text from "../middleware/error_text";
//************************************************************
const refreshToken = localStorage.getItem('refreshToken');
const cryptData = (text) => CryptoJS.AES.encrypt(text, process.env.REACT_APP_PAYLOAD_KEY).toString();

//***************************| - Thunk - |***************************
//*******************************************************************
export const refreshLoginAPI = createAsyncThunk(
    'user/refreshLogin',
    async (_, {rejectWithValue, /*dispatch*/}) => {
        const reqRefreshUser = await loginApi.getRefresh(_, rejectWithValue);
        if (reqRefreshUser.tokens) {
            sessionStorage.setItem('accessToken', reqRefreshUser.tokens.accessToken)
            sessionStorage.setItem('accessId', String(reqRefreshUser.user.id));
            localStorage.setItem('refreshToken', reqRefreshUser.tokens.refreshToken)
        }
        return reqRefreshUser
    }
)
//-------------------------------------------------------------------------------------------------
export const userLoginAPI = createAsyncThunk(
    'user/userLogin',
    async (loginPass, {rejectWithValue}) => {
        const logData = {
            "email": cryptData(loginPass.email),
            "password": cryptData(loginPass.password),
        }
        const remember_me = loginPass.remember_me;
        const reqLoginUser = await loginApi.posLogin(logData, rejectWithValue);

        if (reqLoginUser.tokens) {
            sessionStorage.setItem('accessToken', reqLoginUser.tokens.accessToken);
            sessionStorage.setItem('accessId', String(reqLoginUser.user.id));

            if (remember_me) {
                localStorage.setItem('refreshToken', reqLoginUser.tokens.refreshToken)
            } else {
                sessionStorage.setItem('refreshToken', reqLoginUser.tokens.refreshToken)
            }
        }
        return reqLoginUser
    }
)
//-------------------------------------------------------------------------------------------------
//#################################################################################################
const initialState = {
    isAuth: false,
    login: {},
    loading: false,
    logout: !refreshToken,
    error: '',

}
//#################################################################################################
export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        logout: (state) => {
            sessionStorage.clear()
            localStorage.clear()
            state.logout = true;
            state.isAuth = false;
        },
        isAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        isError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers: {
        //**************************************************************
        //userLoginAPI
        [userLoginAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [userLoginAPI.fulfilled]: (state, action) => {

            if (!action.payload.error && action.payload.user.status === 1) {
                state.login = action.payload.user;
                state.isAuth = true;
            } else {
                if (!action.payload.error && action.payload.user.status === 0) {
                    state.error = 'Користувач не активований!';
                } else {
                    state.error = 'Невірний логін чи пароль!';
                }
            }
            state.loading = false;
        },
        [userLoginAPI.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        //**************************************************************
        //refreshLoginAPI

        [refreshLoginAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [refreshLoginAPI.fulfilled]: (state, action) => {
            if (!action.payload.error && action.payload.user.status === 1) {
                state.login = action.payload.user;
                state.isAuth = true;
            } else {
                state.error = 'Користувач не активований!';
            }
            state.loading = false;
        },
        [refreshLoginAPI.rejected]: (state, action) => {
            const error = Error_text(action.payload);
            state.error = error.text;
            state.loading = false;
        },
        //**************************************************************

    }
})
// Selector ********************************************************
export const userLoginMapStateToProps = (state) => ({
    error: state.login.error,
    loading: state.login.loading,
    logout: state.login.logout,
    user: state.login.login,
});
export const headerMapStateToProps = (state) => ({
    user: state.login.login,
    loading: state.users.loading,

});
//******************************************************************
// Action creators are generated for each case reducer function
export const {
    logout,
    isAuth,
    isError,
} = loginSlice.actions
export default loginSlice.reducer