import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {userApi} from '../services/userService'
import Error_text from "../middleware/error_text";
import {refreshLoginAPI} from "./loginSlice";
import {message} from 'antd';
//***************************| - Thunk - |***************************
const message_error = async (text) => await message.error(text);
const message_warning = (text) => message.warning(text);
//*******************************************************************
export const getUserAPI = createAsyncThunk(
    'user/getUser',
    async (_, thunkAPI/*{rejectWithValue, dispatch}*/) =>
        await userApi.getUser(_, thunkAPI))
//await userApi.getUser(_, thunkAPI.rejectWithValue))
//-------------------------------------------------------------------------------------------------
export const addUserAPI = createAsyncThunk(
    'user/addUser',
    async (data, {rejectWithValue}) => {
        return await userApi.addUser(data, rejectWithValue);
    });
//-------------------------------------------------------------------------------------------------
export const editUserAPI = createAsyncThunk(
    'user/editUser',
    async (data, {rejectWithValue}) => {
        return await userApi.editUser(data, rejectWithValue);
    });
//-------------------------------------------------------------------------------------------------
export const deleteUserAPI = createAsyncThunk(
    'user/deleteUser',
    async (id, {rejectWithValue}) => {
        return await userApi.deleteUser(id, rejectWithValue);
    });
//-------------------------------------------------------------------------------------------------
export const activeUserAPI = createAsyncThunk(
    'user/activeUser',
    async (data, {rejectWithValue}) => {
        return await userApi.activeUser(data, rejectWithValue);
    });
//-------------------------------------------------------------------------------------------------
export const settingsUserAPI = createAsyncThunk(
    'user/settingsUser',
    async (data, {rejectWithValue}) => {
        return await userApi.settingsUser(data, rejectWithValue);
    });
//-------------------------------------------------------------------------------------------------
//#################################################################################################
const initialState = {
    users: [],
    loading: false,
    error: '',

}//#################################################################################################
export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        user_add: (state, action) => {
            state.isAuth = action.payload;
        },
        user_edit: (state, action) => {
            state.isAuth = action.payload;
        },
        user_delete: (state, action) => {
            state.isAuth = action.payload;
        },
    },
    extraReducers: {
        //**************************************************************
        //getUserAPI

        [getUserAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [getUserAPI.fulfilled]: (state, action) => {
            if (!action.payload.error) state.users = action.payload;
            state.loading = false;
        },
        [getUserAPI.rejected]: (state) => {
            state.loading = false;
        },
        //**************************************************************
        //addUserAPI

        [addUserAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [addUserAPI.fulfilled]: (state, action) => {
            if (!action.payload.error) {
                state.users.push(action.payload.user);
            } else {
                state.error = action.payload.error;
            }
            state.loading = false;
        },
        [refreshLoginAPI.rejected]: (state, action) => {
            const error = Error_text(action.payload);
            state.error = error.text;
            state.loading = false;
        },
        //**************************************************************
        //editUserAPI

        [editUserAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [editUserAPI.fulfilled]: (state, {payload}) => {
            if (!payload.error) {
                //console.log('editUserAPI ', payload);
                state.users = state.users.map((item) => {
                    if (item.email !== payload.email) {
                        return item
                    }
                    return {
                        ...item,
                        date: payload.date,
                        name: payload.name,
                        group_us: payload.group_us,
                    }
                })
            } else {
                state.error = payload.error
                (async () => await message_error(payload.error))();
            }
            state.loading = false;
        },
        [editUserAPI.rejected]: (state, action) => {
            const error = Error_text(action.payload);
            state.error = error.text;
            state.loading = false;
        },
        //**************************************************************
        //deleteUserAPI

        [deleteUserAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [deleteUserAPI.fulfilled]: (state, {payload}) => {
            if (!payload.error) {
                state.users = state.users.filter((item) => item.id !== Number(payload.id));
            } else {
                state.error = payload.error
                (async () => await message_error(payload.error))();
            }
            state.loading = false;
        },
        [deleteUserAPI.rejected]: (state, action) => {
            const error = Error_text(action.payload);
            state.error = error.text;
            state.loading = false;
        },
        //**************************************************************
        //activeUserAPI

        [activeUserAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [activeUserAPI.fulfilled]: (state, {payload}) => {
            if (!payload.error) {
                state.users = state.users.map((item) => {
                    if (item.id !== Number(payload.id)) {
                        return item
                    }
                    const to_status = payload.status === 1 ? 'активовано' : 'деактивовано';
                    (async () => await message_warning(`Користувач ${item.name} ${to_status}!`))();
                    return {
                        ...item,
                        status: payload.status
                    }
                })
            } else {
                state.error = payload.error
                (async () => await message_error(payload.error))();
            }
            state.loading = false;
        },
        [activeUserAPI.rejected]: (state, action) => {
            const error = Error_text(action.payload);
            state.error = error.text;
            state.loading = false;
        },
        //**************************************************************
        //settingsUserAPI

        [settingsUserAPI.pending]: (state) => {
            state.error = '';
            state.loading = true;
        },
        [settingsUserAPI.fulfilled]: (state, {payload}) => {
            if (payload.error) {
                state.error = payload.error
                (async () => await message_error(payload.error))();
            }
            state.loading = false;
        },
        [settingsUserAPI.rejected]: (state, action) => {
            const error = Error_text(action.payload);
            state.error = error.text;
            state.loading = false;
        },

    }
})
// Selector ********************************************************
export const usersMapStateToProps = (state) => ({
    error: state.users.error,
    loading: state.users.loading,
    users: state.users.users,
});
//******************************************************************
// Action creators are generated for each case reducer function
export const {
    user_add,
    user_edit,
    user_delete,
} = usersSlice.actions
//--------------------------------
export default usersSlice.reducer
