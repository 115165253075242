import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
    activeUserAPI,
    addUserAPI, deleteUserAPI, editUserAPI,
    getUserAPI,
    usersMapStateToProps
} from "../../store/usersSlice";
import UsersListAndGroups from "./users";

////////////////////////////////////////////////////////////////////////////////////////////////////////
const UsersContainer = (props) => {
    const {getUserAPI} = props;

    useEffect(() => {
        getUserAPI();

    }, [getUserAPI]);

    return (<> <UsersListAndGroups {...props}/> </>)
};

//----------------------------------------------------
export default connect(usersMapStateToProps, {
    addUserAPI,
    getUserAPI,
    editUserAPI,
    activeUserAPI,
    deleteUserAPI,
})(UsersContainer)
