import React from "react";
import {connect} from "react-redux";
import {headerMapStateToProps} from "../../store/loginSlice";
import SettingsUserForm from "./settings";
import {settingsUserAPI} from "../../store/usersSlice";
import {message} from "antd";
////////////////////////////////////////////////////////////////////////////////////////////////////////
const SettingsContainer = (props) => {
    //console.log('SettingsContainer ', props)
    const message_success = (text) => message.success(text);
    //--------------------------------------
    const onSubmitSettingsSave = async (values) => {


        const result = await props.settingsUserAPI(values);
        if (!result.payload.error) {
            message_success(`Налаштування збережено!`);
        }
    };

    return (<>
        <SettingsUserForm {...props} onSubmit={onSubmitSettingsSave}/>
    </>)
};

//----------------------------------------------------
export default connect(headerMapStateToProps, {
    settingsUserAPI,
})(SettingsContainer)
